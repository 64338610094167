import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Modal } from "react-bootstrap";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { BsMoon, BsSun } from "react-icons/bs";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../src/App.css';
import { ScrollToAnchor } from "../scroll-to-anchor/scroll-to-anchor";
import { useState, useEffect } from "react";
import useDarkMode from "./../../hooks/useDarkMode";
import navigationCSS from './css/navigation.css';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { faGlobe, faBell, faCircleInfo, faFireFlameCurved } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSound from 'use-sound';
import Click from './src/click.wav';


export const NavigationBar = () => {
	const ClickThemeLight = () => {
		const [play] = useSound(Click);
		return <button className="toggle_btn pl-3" onClick={() => { play();setDarkMode(!isDarkMode); handleShowDarkModal(); }}>
			<FontAwesomeIcon className="moon" title={t("themeSwitcherDarkHint")} icon={faFireFlameCurved} beatFade style={{ color: "#000000", "--fa-animation-iteration-count": "2" }} />
		</button>
		// onClick={() => {setVisible(!visible)}}
	};


	

	  const ClickThemeDark = () => {

		const [play] = useSound(Click);
		return <button className="toggle_btn pl-3" onClick={() => { play();setDarkMode(!isDarkMode); handleShowLightModal();  }}>

			<FontAwesomeIcon className="sun" title={t("themeSwitcherLightHint")} icon={faFireFlameCurved} beatFade style={{ color: "#FFD43B", "--fa-animation-iteration-count": "2" }} />
		</button>
		// onClick={() => {setVisible(!visible)}}
	};

	const [showDarkModal, setShowDarkModal] = useState(false);
	  const [showLightModal, setShowLightModal] = useState(false);
	  const handleShowLightModal = () => setShowLightModal(true);
	  const handleShowDarkModal = () => setShowDarkModal(true);
	  //   const handleCloseNewVisitorModal = () => setShowNewVisitorModal(false);
	  const handleCloseLightModal = () => setShowLightModal(false);
	  const handleCloseDarkModal = () => setShowDarkModal(false);
	  const [isDarkMode, setDarkMode] = useDarkMode();
	  const [expanded, setExpanded] = useState(false);
	  
	  const { t, i18n } = useTranslation();

	  
	return (
		<>
		<Navbar expanded={expanded} expand="lg" className="page-header bg-body-tertiary mb-3">
		<Container fluid>
		  <Navbar.Brand onClick={() => setExpanded(false)} as={Link} to="/"><span className="logotype">Youjey</span></Navbar.Brand>
		  <Navbar.Toggle onClick={() => {setExpanded(!expanded)}} aria-controls={`offcanvasNavbar-expand-lg`} />
		  <Navbar.Offcanvas
			id={`offcanvasNavbar-expand-lg`}
			aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
			placement="end"
		  >
			<Offcanvas.Header onClick={() => setExpanded(false)} closeButton>
			  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
				Menu
			  </Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
			  <Nav className="offcanv justify-content-end flex-grow-1 pe-3">
				<Nav.Link onClick={() => setExpanded(false)} as={Link} to='/'>Home</Nav.Link>
				{/* <Nav.Link as={Link} to='/poems'>Poems</Nav.Link> */}
				<Nav.Link onClick={() => setExpanded(false)} as={Link} to='/themes'>Themes</Nav.Link>
				<NavDropdown
				  title="Poems"
				  id={`offcanvasNavbarDropdown-expand-lg`}
				>
				  <NavDropdown.Item href="#action3">
				<Nav.Link onClick={() => setExpanded(false)} as={Link} to='/lovepoems'>Love Poems</Nav.Link>

				  </NavDropdown.Item>
				  <NavDropdown.Divider />

				  <NavDropdown.Item href="#action4">
				  <Nav.Link onClick={() => setExpanded(false)} as={Link} to='/philosophypoems'>Philosophy Poems</Nav.Link>

				  </NavDropdown.Item>
				  <NavDropdown.Divider />
				  <NavDropdown.Item href="#action5">
				  <Nav.Link onClick={() => setExpanded(false)} as={Link} to='/socialpoems'>Social Poems</Nav.Link>
				  </NavDropdown.Item>
				</NavDropdown>
				

			  </Nav>
			  <Form className="d-flex center">
				<Form.Control
				  type="search"
				  placeholder="Search a poem"
				  className="me-2"
				  aria-label="Search"
				/>
				<Button className="input-search" variant="outline">Search</Button>
			  </Form>
			  <Nav.Link onClick={() => setExpanded(false)} className="theme-switch" >

				{isDarkMode ? (
								<ClickThemeDark />) : (
								<ClickThemeLight />

							)}
				</Nav.Link>
			</Offcanvas.Body>
		  </Navbar.Offcanvas>
		</Container>
	  </Navbar>
	
<Modal

className="favorite-modal" show={showDarkModal} onHide={handleCloseDarkModal}>
<Modal.Header closeButton>
	{/* <Modal.Title className="text-success">Favorites</Modal.Title> */}
</Modal.Header>
<Modal.Body className="text-dark bg-white dark-modal-body"><FontAwesomeIcon className="pr-2" icon={faCircleInfo} fade style={{ color: "#ffd43b", paddingRight:"6px"}} size="lg" />Dark Theme is on</Modal.Body>

<Button title={t("modalHint")} className="got-it-button text-dark bg-white dark-modal-button" onClick={handleCloseDarkModal}>OK</Button>

</Modal>

<Modal

className="favorite-modal" show={showLightModal} onHide={handleCloseLightModal}>
<Modal.Header closeButton>
	{/* <Modal.Title className="text-success">Favorites</Modal.Title> */}
</Modal.Header>
<Modal.Body className="text-dark bg-white"><FontAwesomeIcon className="pr-2" icon={faCircleInfo} fade style={{ color: "#121212", paddingRight:"6px"}} size="lg" />Light Theme is on

</Modal.Body>

<Button title={t("modalHint")} className="got-it-button light-modal-button" onClick={handleCloseLightModal}>OK</Button>
</Modal>

</>

		
	);
};
