import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import dzelkvy from './img/dzelkvy.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/dzelkvy.mp3'
import video from './audio/dzelkvy.mp4'



export const Dzelkvy = () => {
  return (
    <>
      <Row className='m-auto'>
        <Col className='m-auto col-single-poem'>
          <Card className="card m-auto"
          // style={{ width: '28rem' }}
          >
            <video width="750" height="500" controls autoplay loop muted>
              <source src={video} type="video/mp4" />
            </video>
            {/* <Card.Img className="w-75 m-auto" variant="top" src={dzelkvy} /> */}
            <Card.Body className="">
              <h4 className="text-center single-poem-heading">Дзельквы</h4>
              <Card.Text className="text-center single-poem-text">
                <p>
                  Мимо  проносятся  блеклыми  душами  сотни  похожих  домов.<br />
                  Словно  фонарики  полупотухшие,  люди  под  маской  зонтов.<br />
                  Дзельквы  раздетые  тихо  сутулятся,  мерзнут  в  порывах  дождя.<br />
                  На сих  холодных  и  брошенных  улицах  помнит  ли  кто-то  себя?<br />
                  <br />
                  Помнит  ли  дворник,  в  раздумья  укутанный?  Или  прохожий  в  пальто?<br />
                  Иль часовщик,  что охвачен  минутами?  Зрители  в  теплом  кино?<br />
                  Может  художник,  что  блеклыми  красками  мысли  наносит  на  холст?<br />
                  Или  строители  с  желтыми  касками,  что  создают  новый  мост?<br />
                  <br />
                  Помнит  хоть  кто-нибудь    в  доме,  за  ужином?  В  поезде,  возле  окна?<br />
                  Кто  замерзает  под  ливнями,  стужами?  Кто  утонул  в  крепких  снах?<br />
                  Шагом  неспешным  гуляя  по  улицам,  я  убеждаюсь  сильней:<br />
                  Помнят  лишь  дзельквы,  что  тихо  сутулятся  где-то  в  пугающей  тьме.<br />


                </p>
              </Card.Text>
              <div className='text-center'>
                <audio className='mt-3 mb-3' controls>
                  <source src={audio} type="audio/mpeg" />
                  Your browser does not support the audio element
                </audio>
              </div>
            </Card.Body>
          </Card>


        </Col>


      </Row>

      <Row>
        <Row>
          <Link to={`/philosophypoems`}>

            <Button className="go-back-button" style={{ fontSize: "18px", marginLeft: "12px", marginTop: "16px", marginBottom: "16px" }} variant="outline"><span className='go-back-span'>Go Back</span></Button>
          </Link>
        </Row>
      </Row>
    </>
  );
}

