import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import poemLove from './img/poetry-love.jpg';
import { Link } from 'react-router-dom';
import whatisautumn from './img/whatisautumn.jpg'
import bolero from './img/bolero.jpg';
import dzelkvy from './img/dzelkvy.jpg';
import half from './img/half.jpg';
import halfway from './img/halfway.jpg';
import matrix from './img/matrix.jpg';


import poemPhylosophy from './img/poetry-phylosophy.jpg';
import poemSociety from './img/poetry-society.jpg';

export const PhilosophyPoemCards = () =>{
  return (
    <>
<Row className='row-poem-cards-container'>
    <Col className='poem-cards'>
    <Card className="poem-card"
    style={{ width: '28rem' }}
    >
      <Card.Img variant="top" src={whatisautumn} />
      <Card.Body >
        <h4 className="single-poem-heading">Что такое осень</h4>
        <Card.Text>
          <p>
          Осень это люди, в тесной электричке, и в глазах их небо, грустные тона.<br/>
И когда в тумане, видишь, по привычке, как танцует с ветром желтая листва.<br/>
Осень, это кофе в маленькой кафешке...<br/>

          </p>
        </Card.Text>
        <Link to={`/philosophypoems/whatisautumn`}>

        <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
        </Link>
      </Card.Body>
    </Card>
</Col>
<Col className='poem-cards'>

    <Card className="poem-card"
    style={{ width: '28rem' }}
    >
      <Card.Img variant="top" src={bolero} />
      <Card.Body >
        <h4 className="single-poem-heading">Болеро</h4>
        <Card.Text>
        <p>
        Весь город спит, как будто вечно, и за окном нет ни души,
И на стенах животрепещат вновь предзакатные лучи.
Нет шума суетливых улиц...<br/>

          </p>
        </Card.Text>
        <Link to={`/philosophypoems/bolero`}>

        <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
        </Link>
      </Card.Body>
    </Card>
</Col>
<Col className='poem-cards'>

    <Card className="poem-card"
    style={{ width: '28rem' }}
    >
      <Card.Img variant="top" src={dzelkvy} />
      <Card.Body >
        <h4 className="single-poem-heading">Дзельквы</h4>
        <Card.Text>
        <p>
        Мимо  проносятся  блеклыми  душами  сотни  похожих  домов.<br/>
Словно  фонарики  полупотухшие,  люди  под  маской  зонтов.<br/>
Дзельквы  раздетые  тихо  сутулятся...<br/>

          </p>
        </Card.Text>
        <Link to={`/philosophypoems/dzelkvy`}>

        <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
        </Link>
      </Card.Body>
    </Card>
    </Col>
    
    
    </Row>
    

    <Row className='row-poem-cards-container '>

    <Col className='poem-cards m-auto mt-3 '>

<Card className="poem-card m-auto"
style={{ width: '28rem' }}
>
  <Card.Img variant="top" src={half} />
  <Card.Body >
    <h4 className="single-poem-heading">Полураспад</h4>
    <Card.Text>
    <p>
    Взглянул на солнце золотое.<br/>
Увидел черную дыру.<br/>
Спрошу дыру ту: «Кто я? Кто я?»<br/>

      </p>
    </Card.Text>
    <Link to={`/philosophypoems/half`}>

    <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
    </Link>
  </Card.Body>
</Card>
</Col>

<Col className='poem-cards '>

<Card className="poem-card"
style={{ width: '28rem' }}
>
  <Card.Img variant="top" src={halfway} />
  <Card.Body >
    <h4 className="single-poem-heading">на полпути</h4>
    <Card.Text>
    <p>
    на полпути сомкнулся круг.<br/>
куда теперь, мой верный друг?<br/>
дождливый день, угрюма ночь<br/>

      </p>
    </Card.Text>
    <Link to={`/philosophypoems/halfway`}>

    <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
    </Link>
  </Card.Body>
</Card>
</Col>

<Col className='poem-cards '>

<Card className="poem-card"
style={{ width: '28rem' }}
>
  <Card.Img variant="top" src={matrix} />
  <Card.Body >
    <h4 className="single-poem-heading">Red pill, blue pill</h4>
    <Card.Text>
    <p>
    Я знаю почему сидишь за монитором<br/>
И ночи напролет не покидаешь сеть.<br/>
Я знаю почему стал иллюзорен город,<br/>

      </p>
    </Card.Text>
    <Link to={`/philosophypoems/matrix`}>

    <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
    </Link>
  </Card.Body>
</Card>
</Col>

</Row>

    <Row className='back-row'>
      <Link to={`/themes`}>

<Button className="button-go-back" style={{fontSize:"18px", marginLeft:"12px", marginTop:"16px", marginBottom:"16px"}} variant="outline"><span className='go-back-span'>Go Back</span></Button>
</Link>
    </Row>
    </>
  );
}

