import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import flare from './img/flare.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/flare.mp3'
import video from './audio/flare.mp4'



export const MortalFlare = () =>{
  return (
    <>
<Row className='m-auto'>
    <Col  className='m-auto col-single-poem'>
    <Card className="card m-auto"
    // style={{ width: '28rem' }}
    >
      {/* <Card.Img className="w-75 m-auto" variant="top" src={flare} /> */}
      <video width="750" height="500" controls autoplay loop muted>
          <source src={video} type="video/mp4" />
        </video>
      <Card.Body className="">
        <h4 className="text-center single-poem-heading">A mortal flare</h4>
        <Card.Text className="text-center single-poem-text">
          <p>
          How often did you watch me cry?<br/>
The countless tears to borrow<br/>
For your unchanging moody sky<br/>
To paint it with my sorrow.<br/>
<br/>
I set aside my hope and love<br/>
To have your understanding.<br/>
I tried to keep it with a vow,<br/>
But it was never ending.<br/>
<br/>
I don’t pretend to be a star.<br/>
Oh, no, how can I dare?<br/>
Just something you could see afar.<br/>
I guess a mortal flare.<br/>

 


          </p>
        </Card.Text>

        <div className='text-center'>
                <audio className='mt-3 mb-3' controls>
                  <source src={audio} type="audio/mpeg" />
                  Your browser does not support the audio element
                </audio>
              </div>
        </Card.Body>
    </Card>

    
    </Col>
    
    
    </Row>

<Row>
<Link to={`/lovepoems`}>

        <Button className="go-back-button" style={{fontSize:"18px", marginLeft:"12px", marginTop:"16px", marginBottom:"16px"}} variant="outline"><span className='go-back-span'>Go Back</span></Button>
        </Link>
</Row>
</>
  );
}

