import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import indiff from './img/indiff.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/indiff.mp3'
import video from './audio/indiff.mp4'



export const Indiff = () => {
  return (
    <>
      <Row className='m-auto'>
        <Col className='m-auto col-single-poem'>
          <Card className="card m-auto"
          // style={{ width: '28rem' }}
          >
            {/* <Card.Img className="w-75 m-auto" variant="top" src={indiff} /> */}
            <video width="750" height="500" controls autoplay loop muted>
              <source src={video} type="video/mp4" />
            </video>
            <Card.Body className="">
              <h4 className="text-center single-poem-heading">Отчужденность</h4>
              <Card.Text className="text-center single-poem-text">
                <p>
                Отчужденностью воздух пропитано, и постели в холодных купе,<br/>
Отчужденностью люди воспитаны, предоставлены сами себе.<br/>
Отчужденность холодными тучами, нависает туманом внутри,<br/>
Отчужденностью души научены обреченно по миру идти.<br/>
<br/>
В этой бездне случайных прохожих, словно в море протянутых рук,<br/>
Меж собой все тонущие схожи, утопающим брошено круг.<br/>
Кто-то молча хватает веревку, кто-то злостно посмотрит вам вслед.<br/>
Никакого в спасении толку, если в мыслях его совсем нет.<br/>
<br/>
То туда то сюда оболочки вдоль по улице тихо идут.<br/>
Как чернильные мелкие строчки, по бумажным проулкам бредут.<br/>
В этом мире безумном и грустном, человек стал похож на сундук:<br/>
В сундуке одиноко и пусто, ни ключа, ни отмычки вокруг.<br/>

                </p>
              </Card.Text>
              <div className='text-center'>
                <audio className='mt-3 mb-3' controls>
                  <source src={audio} type="audio/mpeg" />
                  Your browser does not support the audio element
                </audio>
              </div>
            </Card.Body>
          </Card>


        </Col>


      </Row>

      <Row>
        <Link to={`/socialpoems`}>

          <Button className="go-back-button" style={{ fontSize: "18px", marginLeft: "12px", marginTop: "16px", marginBottom: "16px" }} variant="outline"><span className='go-back-span'>Go Back</span></Button>
        </Link>
      </Row>
    </>
  );
}

