import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import half from './img/half.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/half.mp3'
import video from './audio/half.mp4'



export const Half = () => {
  return (
    <>
      <Row className='m-auto'>
        <Col className='m-auto col-single-poem'>
          <Card className="card m-auto"
          // style={{ width: '28rem' }}
          >
            {/* <Card.Img className="w-75 m-auto" variant="top" src={half} /> */}
            <video width="750" height="500" controls autoplay loop muted>
              <source src={video} type="video/mp4" />
            </video>
            <Card.Body className="">
              <h4 className="text-center single-poem-heading">Полураспад</h4>
              <Card.Text className="text-center single-poem-text">
                <p>
                  Взглянул на солнце золотое.<br />
                  Увидел черную дыру.<br />
                  Спрошу дыру ту: «Кто я? Кто я?»<br />
                  И в тот же миг полуумру.<br />
                  <br />
                  Полунадеждой сыт не будешь.<br />
                  А полужизни краше смерть.<br />
                  Чего ж безмолвно, Боже, судишь?<br />
                  Ведь ты – на треть. И я - на треть.<br />
                  <br />
                  Со мраком лодкой до рассвета.<br />
                  Жизнь нам готовит водопад.<br />
                  Упрямых много нас, поэтов.<br />
                  У всех есть свой полураспад.<br />

                </p>
              </Card.Text>
              <div className='text-center'>
                <audio className='mt-3 mb-3' controls>
                  <source src={audio} type="audio/mpeg" />
                  Your browser does not support the audio element
                </audio>
              </div>
            </Card.Body>
          </Card>


        </Col>


      </Row>
      <Row>
        <Link to={`/philosophypoems`}>

          <Button className="go-back-button" style={{ fontSize: "18px", marginLeft: "12px", marginTop: "16px", marginBottom: "16px" }} variant="outline"><span className='go-back-span'>Go Back</span></Button>
        </Link>
      </Row>

    </>
  );
}

