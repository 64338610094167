import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import promise from './img/promise.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/promise.mp3'
import video from './audio/promise.mp4'



export const PromisePoem = () =>{
  return (
    <>
<Row className='m-auto'>
    <Col  className='m-auto col-single-poem'>
    <Card className="card m-auto"
    // style={{ width: '28rem' }}
    >
      {/* <Card.Img className="w-75 m-auto" variant="top" src={promise} /> */}
      <video width="750" height="500" controls autoplay loop muted>
              <source src={video} type="video/mp4" />
            </video>
      <Card.Body className="">
        <h4 className="text-center single-poem-heading">Обіцяй</h4>
        <Card.Text className="text-center single-poem-text">
          <p>
          Вкинь до валізи одяг.<br/>
Сядь на найближчий потяг.<br/>
Їдь на край світу. В море.<br/>
Щоб прокричати горе.<br/>
<br/>
Щоб той солоний вітер<br/>
Стер увесь біль із літер.<br/>
Щоб, як умів кохати, -<br/>
Так припинив писати.<br/>
<br/>
Хочеш свій голос викинь.<br/>
Щоб не почули крику.<br/>
Адже душа німіє,<br/>
Аж говорить не вмієш.<br/> 
<br/>
Викинь всі вірші в хвилі.<br/>
Кров утопи чорнильну.<br/>
І обіцяй, що більше<br/>
Ти не напишеш віршів.<br/>

 


          </p>
        </Card.Text>

        <div className='text-center'>
                <audio className='mt-3 mb-3' controls>
                  <source src={audio} type="audio/mpeg" />
                  Your browser does not support the audio element
                </audio>
              </div>
        </Card.Body>
    </Card>

    
    </Col>
    
    
    </Row>

<Row>
<Link to={`/lovepoems`}>

        <Button className="go-back-button" style={{fontSize:"18px", marginLeft:"12px", marginTop:"16px", marginBottom:"16px"}} variant="outline"><span className='go-back-span'>Go Back</span></Button>
        </Link>
</Row>
</>
  );
}

