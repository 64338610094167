import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import unforgiven from './img/unforgiven.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/unforgiven.mp3'
import video from './audio/unforgiven.mp4'



export const Unforgiven = () => {
  return (
    <>
      <Row className='m-auto'>
        <Col className='m-auto col-single-poem'>
          <Card className="card m-auto"
          // style={{ width: '28rem' }}
          >
            <video width="750" height="500" controls autoplay loop muted>
    <source src={video} type="video/mp4" />
  </video>
            {/* <Card.Img className="w-75 m-auto" variant="top" src={unforgiven} /> */}
            <Card.Body className="">
              <h4 className="text-center single-poem-heading">Непрощенный</h4>
              <Card.Text className="text-center single-poem-text">
                <p>
                Чтобы сердце тебя успокоило,<br/>
Попытаешься вырвать из памяти<br/>
Тех, кто прежде был важным и памятным.<br/>
Вырвешь тех, кого лучше не стило б.<br/>
Ты затерян бескрайними рощами,<br/>
И остался навеки непрощенным.<br/>
<br/>
Дрёмы нет, душит мгла отвратительно. <br/> 
Станешь автором собственной повести.<br/>
И сожжешь угрызения совести,<br/>
Чтоб они возвратились стремительно.<br/>
Ты блуждаешь угрюмыми рощами,<br/>
И остался навеки непрощенным.<br/>
<br/>
Под дождем. Таксофон. И ты трешь виски.<br/>
Номера..номера..лихорадочно,<br/>, <br/>
Разорвать и пуститься на поиски,<br/>
Под ночными холодными рощами,<br/>
Где остался собой ты не прощенный.<br/>

                </p>
              </Card.Text>
              <div className='text-center'>
                <audio className='mt-3 mb-3' controls>
                  <source src={audio} type="audio/mpeg" />
                  Your browser does not support the audio element
                </audio>
              </div>
            </Card.Body>
          </Card>


        </Col>


      </Row>

      <Row>
        <Link to={`/socialpoems`}>

          <Button className="go-back-button" style={{ fontSize: "18px", marginLeft: "12px", marginTop: "16px", marginBottom: "16px" }} variant="outline"><span className='go-back-span'>Go Back</span></Button>
        </Link>
      </Row>
    </>
  );
}

