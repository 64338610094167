import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import whatisautumn from './img/whatisautumn.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/autumn.mp3'
import video from './audio/rain.mp4'



export const WhatIsAutumn = () => {
  return (
    <>
      <Row className='m-auto'>
        <Col className='m-auto col-single-poem'>
          <Card className="card m-auto"
          // style={{ width: '28rem' }}
          >
            <video width="750" height="500" controls autoplay loop muted>
              <source src={video} type="video/mp4" />
            </video>
            {/* <Card.Img className="w-75 m-auto" variant="top" src={whatisautumn} /> */}
            <Card.Body className="">
              <h4 className="text-center single-poem-heading">Что такое осень</h4>
              <Card.Text className="text-center single-poem-text">
                <p>
                  Осень это люди, в тесной электричке, и в глазах их небо, грустные тона.<br />
                  И когда в тумане, видишь, по привычке, как танцует с ветром желтая листва.<br />
                  Осень, это кофе в маленькой кафешке, где собрались люди в поисках тепла,<br />
                  За окном под ливнем в непривычной спешке, бегают с зонтами, по своим делам.<br />
                  <br />
                  Осень это письма, много длинных писем, время, что осталось в строчках из чернил.<br />
                  И когда считаешь по минутам числа, и живешь стихами, что ты сочинил.<br />
                  Осень это окна в стареньких высотках, что кидают мягкий, нежный желтый свет.<br />
                  И играет тихо музыка в колонках, греет вечерами твой любимый плед.<br />
                  <br />
                  Осень это запах новой фотопленки, соло, что играет джазовый кларнет,<br />
                  Это музыканты, что стоят в сторонке, а вокруг витает дым от сигарет.<br />
                  Под холодным ливнем выйду на конечной, прошепчу тихонько пару странных слов:<br />
                  «Осень – это чувство, осень будет вечно, осень не покинет этих берегов».<br />

                </p>
              </Card.Text>
              <div className='text-center'>
                <audio className='mt-3 mb-3' controls>
                  <source src={audio} type="audio/mpeg" />
                  Your browser does not support the audio element
                </audio>
              </div>
            </Card.Body>
          </Card>


        </Col>


      </Row>

      <Row>
        <Link to={`/philosophypoems`}>

          <Button className="go-back-button" style={{ fontSize: "18px", marginLeft: "12px", marginTop: "16px", marginBottom: "16px" }} variant="outline"><span className='go-back-span'>Go Back</span></Button>
        </Link>
      </Row>
    </>
  );
}

