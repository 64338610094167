import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import poemLove from './img/poetry-love.jpg';
import { Link } from 'react-router-dom';
import { PhilosophyPoemCards } from '../philosophy-poem-cards/philosophy-poem-cards';
import poemPhilosophy from './img/poetry-phylosophy.jpg';
import poemSociety from './img/poetry-society.jpg';

export const PoemCards = () =>{
  return (
    <>
<Row className='row-poem-cards-container'>
    <Col className='poem-cards'>
    <Card className="poem-card" style={{ width: '28rem' }}>
      <Card.Img variant="top" src={poemLove} />
      <Card.Body>
        <Card.Title className="single-poem-heading">Love Poetry</Card.Title>
        <Card.Text>
          Poems written on the topic of love, sentimental feelings, and belonging
        </Card.Text>
        <Link to={`/lovepoems`}>

        <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read</Button>
      </Link>
      </Card.Body>
    </Card>
    </Col>
    <Col className='poem-cards'>
    <Card className="poem-card" style={{ width: '28rem' }}>
      <Card.Img variant="top" src={poemPhilosophy} />
      <Card.Body>
        <Card.Title className="single-poem-heading">Philosophical Poetry</Card.Title>
        <Card.Text>
          Poems written on the topic of existence, people's place on Earth, and sense of being
        </Card.Text>
        <Link to={`/philosophypoems`}>
        <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>
Read
        </Button>
        </Link>
      </Card.Body>
    </Card>
    </Col>

    <Col className='poem-cards'>
    <Card className="poem-card" style={{ width: '28rem' }}>
      <Card.Img variant="top" src={poemSociety} />
      <Card.Body>
        <Card.Title className="single-poem-heading">Social Poetry</Card.Title>
        <Card.Text>
          Poems written on the topic of social problems, communication, and people's relationships
        </Card.Text>
        <Link to={`/socialpoems`}>

        <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read</Button>
      </Link>
      </Card.Body>
    </Card>
    </Col>
    
    </Row>
    <Row className='back-row'>
    <Link to={`/`}>
    
            <Button className="go-back-button" style={{fontSize:"18px", marginLeft:"12px", marginTop:"16px", marginBottom:"16px"}} variant="outline"><span className='go-back-span'>Go Back</span></Button>
            </Link>
    </Row>
        
        </>
  );
}

