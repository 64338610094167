import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import { Link } from 'react-router-dom';
import letters from './img/letters.jpg'
import loneliness from './img/loneliness.jpg';
import words2 from './img/words2.jpg';
import emptycity from './img/emptycity.jpg';
import indiff from './img/indiff.jpg';
import unforgiven from './img/unforgiven.jpg';



export const SocialPoemCards = () =>{
  return (
    <>
<Row className='row-poem-cards-container'>
    <Col className='poem-cards'>
    <Card className="poem-card"
    style={{ width: '28rem' }}
    >
      <Card.Img variant="top" src={letters} />
      <Card.Body >
        <h4>Листи</h4>
        <Card.Text>
          <p>
          Час більше не ходить, біжить безупинно.<br/>
І мій час спливає, не хоче іти.<br/>
Яка ж насолода, в самотні хвилини<br/>

          </p>
        </Card.Text>
        <Link to={`/socialpoems/letters`}>

        <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
        </Link>
      </Card.Body>
    </Card>
</Col>
<Col className='poem-cards'>

    <Card className="poem-card"
    style={{ width: '28rem' }}
    >
      <Card.Img variant="top" src={words2} />
      <Card.Body >
        <h4>Слова</h4>
        <Card.Text>
        <p>
        Слова.. отрута в них і світло,<br/>
Людська надія і жага.<br/>
Їх складність форм і простота,<br/>


          </p>
        </Card.Text>
        <Link to={`/socialpoems/words`}>

        <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
        </Link>
      </Card.Body>
    </Card>
</Col>
<Col className='poem-cards'>

    <Card className="poem-card"
    style={{ width: '28rem' }}
    >
      <Card.Img variant="top" src={loneliness} />
      <Card.Body >
        <h4>Одиночество</h4>
        <Card.Text>
        <p>
        Я  надеваю  на сумрачный  лик,  будто бы мим я невыспанный<br/>
Маску  веселья,  к  которой  привык,  грустный свой грим, но изысканный.<br/>
Ночью  на  улицах  люди  в  пальто,  призрачным  счастьем  наполнены...<br/>

          </p>
        </Card.Text>
        <Link to={`/socialpoems/loneliness`}>

        <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
        </Link>
      </Card.Body>
    </Card>
    </Col>
    

    

    
    </Row>


    <Row className='row-poem-cards-container'>
      
    <Col className='poem-cards'>

<Card className="poem-card"
style={{ width: '28rem' }}
>
  <Card.Img variant="top" src={emptycity} />
  <Card.Body >
    <h4>Город стал пуст</h4>
    <Card.Text>
    <p>
    Где-то  за  окнами,  в  чёрных  плащах,<br/>
Ходят  печальные  люди.<br/>
Я,  между  строками,  в  сонных  глазах,<br/>

      </p>
    </Card.Text>
    <Link to={`/socialpoems/emptycity`}>

    <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
    </Link>
  </Card.Body>
</Card>
</Col>
    <Col className='poem-cards'>

<Card className="poem-card m-auto mt-4"
style={{ width: '28rem' }}
>
  <Card.Img variant="top" src={indiff} />
  <Card.Body >
    <h4 className="single-poem-heading">Отчужденность</h4>
    <Card.Text>
    <p>
    Отчужденностью воздух пропитано, и постели в холодных купе,<br/>
Отчужденностью люди воспитаны, предоставлены сами себе.<br/>

      </p>
    </Card.Text>
    <Link to={`/socialpoems/indiff`}>

    <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
    </Link>
  </Card.Body>
</Card>
</Col>


<Col className='poem-cards'>

<Card className="poem-card m-auto mt-4"
style={{ width: '28rem' }}
>
  <Card.Img variant="top" src={unforgiven} />
  <Card.Body >
    <h4 className="single-poem-heading">Непрощенный</h4>
    <Card.Text>
    <p>
    Чтобы сердце тебя успокоило,<br/>
Попытаешься вырвать из памяти<br/>
Тех, кто прежде был важным и памятным.<br/>

      </p>
    </Card.Text>
    <Link to={`/socialpoems/unforgiven`}>

    <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
    </Link>
  </Card.Body>
</Card>
</Col>
</Row>


    <Row className='back-row'>
      <Link to={`/themes`}>

<Button className="button-go-back" style={{fontSize:"18px", marginLeft:"12px", marginTop:"16px", marginBottom:"16px"}} variant="outline"><span className='go-back-span'>Go Back</span></Button>
</Link>
    </Row>
    </>
  );
}

