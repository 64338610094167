import { useEffect, useState } from 'react';
// import { ScrollToTop } from '../ScrollToTop/scroll-to-top';
// import { Spin } from '../spinner/spinner';
import { Letters } from '../letters/letters';
import { BeThere } from '../bethere/bethere';
import { Loneliness } from '../loneliness/;oneliness';
import { MortalFlare } from '../mortal-flare/mortal-flare';
import { SocialPoemCards } from '../social-poem-cards/social-poem-cards';
import { ShouldHaveBeenSo } from '../should-have-been-so/should-have-been-so';
import { TillNovember } from '../tillnovember/tillnovember';
import { Unforgiven } from '../unforgiven/unforgiven';
import { Halfway } from '../halfway/halfway';
import { EmptyCity } from '../emptycity/emptycity';
import { Matrix } from '../matrix/matrix';
import { Angel } from '../angel/angel';
import { Half } from '../half/half';
import { Loader } from '../loader/loader';
import { LovePoemCards } from '../love-poem-cards/love-poem-cards';
import { PoemsView } from '../poems-view/poems-view';
import { PromisePoem } from '../promisepoem/promisepoem';
import { Indiff } from '../indiff/indiff';
import { Bolero } from '../bolero/bolero';
import { PoemCards } from '../poem-cards/poem-cards';
import { WhatIsAutumn } from '../whatIsAutumn/whatIsAutumn';
import { NavigationBar } from '../navigation-bar/navigation-bar';
import {PhilosophyPoemCards} from './../philosophy-poem-cards/philosophy-poem-cards';
import { Dzelkvy } from '../dzelkvy/dzelkvy';
import { Words } from '../words/words';
// import { ProjectsView } from '../projects-view/projects-view';
import { Fragment } from 'react';
// import { ScrollButton } from '../scroll-button/ScrollButton';
// import { ImpressumView } from '../impressum-view/impressum-view';
import { PageNotFoundView } from '../page-not-found-view/page-not-found-view';
import { Footer } from '../footer/footer';
// import { ContactView } from '../contact-view/contact-view';
import { AboutView } from '../about-view/about-view';
// import { TestimonialsView } from '../testimonials-view/testimonials-view';
import { Row, Col, Image } from 'react-bootstrap';
import '../../../src/App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import { R3playCaseView } from '../r3play-case-view/r3play-case-view';
// import { MyFlixCaseView } from '../myFlix-case-view/myFlix-case-view';
// import { PortfolioCaseView } from '../portfolio-case-view/portfolio-case-view';
// import { MeetCaseView } from '../meet-case-view/meet-case-view';
// import { OwriteCaseView } from '../owrite-case-view/owrite-case-view';
// import { PokemonCaseView } from '../pokemon-case-view/pokemon-case-view';
// import { ToDoCaseView } from '../to-do-case-view/to-do-case-view';
import React from "react";
import ScrollToTop from "./../scroll/scroll-to-top";
import logoDark from './img/logo512-dark.png';
import logoWhite from './img/logo512-white.png';
// import { useEffect } from 'react';
import { ScrollToTopButton } from '../scroll-to-top-button/scroll-to-top-button';

// import { UncontrolledExample } from '../Carousel/carousel';
export const MainView = () => {

  
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2300);
  }, []);



  useEffect(() => {
    // 👇️ Scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);


  return (
    <>
      {loading ? (
       
        <div className="loader-container">
          <Loader/>
            {/* <div className="spinner"></div> */}
            {/* <Image className="logo-pulse" src={logoWhite}/> */}

        </div>
        
        
      ) : (
        <>
        <NavigationBar


        />
       
          <Routes>




            <Route
              path="/"
              element={
                <>

                  <Col>
                    <AboutView className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />


<Route
              path="/themes"
              element={
                <>

                  <Col>
                    <PoemCards className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />

<Route
              path="/philosophypoems"
              element={
                <>

                  <Col>
                    <PhilosophyPoemCards className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />

<Route
              path="/lovepoems"
              element={
                <>

                  <Col>
                    <LovePoemCards className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />


<Route
              path="/socialpoems"
              element={
                <>

                  <Col>
                    <SocialPoemCards className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />

<Route
              path="/philosophypoems/whatisautumn"
              element={
                <>

                  <Col>
                    <WhatIsAutumn className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />


<Route
              path="/philosophypoems/halfway"
              element={
                <>

                  <Col>
                    <Halfway className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />

<Route
              path="/philosophypoems/matrix"
              element={
                <>

                  <Col>
                    <Matrix className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />


<Route
              path="/socialpoems/unforgiven"
              element={
                <>

                  <Col>
                    <Unforgiven className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />

<Route
              path="/socialpoems/words"
              element={
                <>

                  <Col>
                    <Words className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />

<Route
              path="/socialpoems/letters"
              element={
                <>

                  <Col>
                    <Letters className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />

<Route
              path="/socialpoems/loneliness"
              element={
                <>

                  <Col>
                    <Loneliness className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />

<Route
              path="/philosophypoems/bolero"
              element={
                <>

                  <Col>
                    <Bolero className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />

<Route
              path="/philosophypoems/dzelkvy"
              element={
                <>

                  <Col>
                    <Dzelkvy className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />





<Route
              path="/lovepoems/angel"
              element={
                <>

                  <Col>
                    <Angel className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />



<Route
              path="/lovepoems/shouldhavebeenso"
              element={
                <>

                  <Col>
                    <ShouldHaveBeenSo className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />

<Route
              path="/lovepoems/mortalflare"
              element={
                <>

                  <Col>
                    <MortalFlare className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />

<Route
              path="/socialpoems/indiff"
              element={
                <>

                  <Col>
                    <Indiff className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />


<Route
              path="/socialpoems/emptycity"
              element={
                <>

                  <Col>
                    <EmptyCity className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />


<Route
              path="/lovepoems/bethere"
              element={
                <>

                  <Col>
                    <BeThere className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />

<Route
              path="/lovepoems/tillnovember"
              element={
                <>

                  <Col>
                    <TillNovember className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />

            <Route
        
              path="*"
              
              element={
              <>
              <PageNotFoundView />
          
              <ScrollToTop />
<ScrollToTopButton/>
            
              </>
            }
            
            />
            
            <Route
              path="/lovepoems/promise"
              element={
                <>

                  <Col>
                    <PromisePoem className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />


<Route
              path="/philosophypoems/half"
              element={
                <>

                  <Col>
                    <Half className="content"

                    />
                    <Footer />
           
                  </Col>
                  <ScrollToTop />
<ScrollToTopButton/>

                </>
              }

            />

            <Route
        
              path="*"
              
              element={
              <>
              <PageNotFoundView />
          
              <ScrollToTop />
<ScrollToTopButton/>
            
              </>
            }
            
            /> 
          </Routes>
         
        
          
          </>
      )}
    </>
  );
};



