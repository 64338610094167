import { useTranslation } from "react-i18next";
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faDev, faTwitter, faMedium } from '@fortawesome/free-brands-svg-icons';
import footerCSS from './css/footer.css';
import { Link } from "react-router-dom";

export const Footer = () =>{
  const { t } = useTranslation();
	const footer = [
		{
		  title: t("footer.copy"),
		  // ...
		},
	  ];
    
      
      
      
    return (
    <>
<footer className="main-footer footer-404 pt-5 pb-4 w-100">
    
    

    
    <p className="fade-in-up-on-scroll copyright text-center">© Youjey, 2024</p>
    <p className="fade-in-up-on-scroll rights text-center">All rights reserved</p>

    <div className="footer-links-container">
   
    
    </div>


</footer>
</>

  
    );
};


