
import { Navbar, Container, Row, Col, Nav, Image } from "react-bootstrap";
import pageNotFoundViewCSS from "./css/404.css";
import image404 from './img/404.gif';
import { Link } from "react-router-dom";
import {Footer} from "../footer/footer";
import { useTranslation } from 'react-i18next';

export const PageNotFoundView = () => {
    const { t } = useTranslation();
    return(
        <>
        <Container fluid className="ps-0 pe-0 w-100 wrapper mt-5">
           
                <Col xs={10} sm={10} md={10} className=" container-404 text-center m-auto" >
        {/* <Image src={image404} className="picture-404 m-auto w-100 mt-5 mb-5" 
        alt={t("notFoundImageAlt")} 
        /> */}
        <div className="main-information_container m-auto mt-5">
            <h1 style={{fontSize:"36px"}} className="r3play-headings">Resource not found</h1>
            <p className="overview-404">But the <Link className="r3play-headings" to="/">Homepage</Link> is a good place to start</p>

        </div>
                </Col>
           
            
        </Container>
        <Footer className="footer-404 w-100" />
        </>
    )
}


