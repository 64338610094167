import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import angel from './img/angel.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/angel.mp3'
import video from './audio/angel.mp4'



export const Angel = () =>{
  return (
    <>
<Row className='m-auto'>
    <Col  className='m-auto col-single-poem'>
    <Card className="card m-auto"
    // style={{ width: '28rem' }}
    >
      {/* <Card.Img className="w-75 m-auto" variant="top" src={angel} /> */}
      <video width="750" height="500" controls autoplay loop muted>
              <source src={video} type="video/mp4" />
            </video>
      <Card.Body className="">
        <h4 className="text-center single-poem-heading">Ангел</h4>
        <Card.Text className="text-center single-poem-text">
          <p>
          Ты - ангел во плоти.<br/>
Смешавший веру с кровью.<br/>
Своей святой любовью<br/>
Тавро жжешь на груди.<br/>
<br/>
Ты ангел во плоти.<br/>
И тесно крыльям в стенах.<br/>
И, чтоб не резать вены,<br/>
Другим не дашь парить.<br/>
<br/>
Ты ангел во плоти.<br/>
И Бог тебе - бравада.<br/>
Его тебе не надо,<br/>
Так—сяк жизнь сколотить.<br/>
<br/>
Ты ангел во плоти,<br/>
Чьим наказаньем-даром<br/>
Есть жить и жить без кары,<br/>
Пересекать пути.<br/>

          </p>
        </Card.Text>
        <div className='text-center'>
        <audio className='mt-3 mb-3' controls>
<source  src={audio} type="audio/mpeg" />
Your browser does not support the audio element
</audio>
</div>
      </Card.Body>
    </Card>

    
    </Col>
    
    
    </Row>

<Row>
<Link to={`/lovepoems`}>

        <Button className="go-back-button" style={{fontSize:"18px", marginLeft:"12px", marginTop:"16px", marginBottom:"16px"}} variant="outline"><span className='go-back-span'>Go Back</span></Button>
        </Link>
</Row>
</>
  );
}

