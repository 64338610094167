import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import november from './img/november.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/november.mp3'
import video from './audio/november.mp4'



export const TillNovember = () =>{
  return (
    <>
<Row className='m-auto'>
    <Col  className='m-auto col-single-poem'>
    <Card className="card m-auto"
    // style={{ width: '28rem' }}
    >
      <video width="750" height="500" controls autoplay loop muted>
              <source src={video} type="video/mp4" />
            </video>
      {/* <Card.Img className="w-75 m-auto" variant="top" src={november} /> */}
      <Card.Body className="">
        <h4 className="text-center single-poem-heading">До ноября совсем немного</h4>
        <Card.Text className="text-center single-poem-text">
          <p>
          До ноября совсем немного.<br/>
Часы шуршат.<br/>
Когда идешь - твоя дорога -<br/>
Твоя душа.<br/>
<br/>
До ноября совсем немного.<br/>
Так, ерунда.<br/>
Ноябрь, разве это много? -<br/>
Он навсегда.<br/>
<br/>
До ноября совсем немного -<br/>
Одна мечта.<br/>
Ноябрь – счастье, за порогом.<br/>
Ты уже там.<br/>
<br/>
До ноября совсем немного.<br/>
Немного, миг.<br/>
Достаточно пути земного,<br/>
Что длится стих.<br/>

 


          </p>
        </Card.Text>

        <div className='text-center'>
                <audio className='mt-3 mb-3' controls>
                  <source src={audio} type="audio/mpeg" />
                  Your browser does not support the audio element
                </audio>
              </div>
        </Card.Body>
    </Card>

    
    </Col>
    
    
    </Row>

<Row>
<Link to={`/lovepoems`}>

        <Button className="go-back-button" style={{fontSize:"18px", marginLeft:"12px", marginTop:"16px", marginBottom:"16px"}} variant="outline"><span className='go-back-span'>Go Back</span></Button>
        </Link>
</Row>
</>
  );
}

