import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import { Link } from 'react-router-dom';
import flare from './img/flare.jpg'
import hands from './img/hands.jpg';
import angel from './img/angel.jpg';
import bethere from './img/bethere.jpg';
import november from './img/november.jpg';
import promise from './img/promise.jpg';


export const LovePoemCards = () =>{
  return (
    <>
<Row className='row-poem-cards-container'>
    <Col className='poem-cards'>
    <Card className="poem-card"
    style={{ width: '28rem' }}
    >
      <Card.Img variant="top" src={angel} />
      <Card.Body >
        <h4>Ангел</h4>
        <Card.Text>
          <p>
          Ты - ангел во плоти.<br/>      
Смешавший веру с кровью.<br/>  
Своей святой любовью...<br/>

          </p>
        </Card.Text>
        <Link to={`/lovepoems/angel`}>

        <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
        </Link>
      </Card.Body>
    </Card>
</Col>
<Col className='poem-cards'>

    <Card className="poem-card"
    style={{ width: '28rem' }}
    >
      <Card.Img variant="top" src={hands} />
      <Card.Body >
        <h4>Значить це мало так бути</h4>
        <Card.Text>
        <p>
        Я кажу: «Ти мене будиш<br/>
Від цього світу як смути».<br/>
Шепотом слух мій голубиш:...<br/>

          </p>
        </Card.Text>
        <Link to={`/lovepoems/shouldhavebeenso`}>

        <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
        </Link>
      </Card.Body>
    </Card>
</Col>
<Col className='poem-cards'>

    <Card className="poem-card"
    style={{ width: '28rem' }}
    >
      <Card.Img variant="top" src={flare} />
      <Card.Body >
        <h4>A mortal flare</h4>
        <Card.Text>
        <p>
        How often did you watch me cry?<br/>
The countless tears to borrow<br/>
For your unchanging moody sky...<br/>

          </p>
        </Card.Text>
        <Link to={`/lovepoems/mortalflare`}>

        <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
        </Link>
      </Card.Body>
    </Card>
    </Col>
    




    
    </Row>
<Row className='row-poem-cards-container'>
<Col className='poem-cards'>

<Card className="poem-card"
style={{ width: '28rem' }}
>
  <Card.Img variant="top" src={bethere} />
  <Card.Body >
    <h4>Be there, dark at dawn</h4>
    <Card.Text>
    <p>
    Be there, dark at dawn,<br/>
When I run out of prayers.<br/>
Words sound like they’re betrayers.<br/>

      </p>
    </Card.Text>
    <Link to={`/lovepoems/bethere`}>

    <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
    </Link>
  </Card.Body>
</Card>
</Col>
<Col className='poem-cards'>

<Card className="poem-card"
style={{ width: '28rem' }}
>
  <Card.Img variant="top" src={november} />
  <Card.Body >
    <h4>До ноября совсем немного</h4>
    <Card.Text>
    <p>
    До ноября совсем немного.<br/>
Часы шуршат.<br/>
Когда идешь - твоя дорога -<br/>


      </p>
    </Card.Text>
    <Link to={`/lovepoems/tillnovember`}>

    <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
    </Link>
  </Card.Body>
</Card>
</Col>

<Col className='poem-cards'>

<Card className="poem-card"
style={{ width: '28rem' }}
>
  <Card.Img variant="top" src={promise} />
  <Card.Body >
    <h4>Обіцяй</h4>
    <Card.Text>
    <p>
    Вкинь до валізи одяг.<br/>
Сядь на найближчий потяг.<br/>
Їдь на край світу. В море.<br/>
      </p>
    </Card.Text>
    <Link to={`/lovepoems/promise`}>

    <Button className="button-read-more" style={{backgroundColor:"whitesmoke", color:"#121212", border:"1px solid #121212"}}>Read more</Button>
    </Link>
  </Card.Body>
</Card>
</Col>

</Row>
    <Row className='back-row'>
      <Link to={`/themes`}>

<Button className="button-go-back" style={{fontSize:"18px", marginLeft:"12px", marginTop:"16px", marginBottom:"16px"}} variant="outline"><span className='go-back-span'>Go Back</span></Button>
</Link>
    </Row>
    </>
  );
}

