import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import emptycity from './img/emptycity.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/emptycity.mp3'
import video from './audio/lamp.mp4'



export const EmptyCity = () => {
  return (
    <>
      <Row className='m-auto'>
        <Col className='m-auto col-single-poem'>
          <Card className="card m-auto"
          // style={{ width: '28rem' }}
          >
            {/* <Card.Img className="w-75 m-auto" variant="top" src={emptycity} /> */}
            <video width="750" height="500" controls autoplay loop muted>
                  <source src={video} type="video/mp4" />
                </video>
            <Card.Body className="">
              <h4 className="text-center single-poem-heading">Город стал пуст</h4>
              <Card.Text className="text-center single-poem-text">
                <p>
                  Где-то  за  окнами,  в  чёрных  плащах,<br />
                  Ходят  печальные  люди.<br />
                  Я,  между  строками,  в  сонных  глазах,<br />
                  Вижу  проклятые  будни.<br />
                  <br />
                  Птицы  из  клеток  в  холодной  груди<br />
                  Рвутся  к  излюбленным  тучам.<br />
                  Но,  у  скелетов,  нет  сердца,  увы.<br />
                  Может  оно  даже  лучше.<br />
                  <br />
                  Полон  людей,  этот  город  стал  пуст<br />
                  Воздух  наполнен  печалью.<br />
                  Шепот  забытый  с  обветренных  уст<br />
                  Эхом  пронесся  вокзальным.<br />



                </p>
              </Card.Text>
              <div className='text-center'>
                <audio className='mt-3 mb-3' controls>
                  <source src={audio} type="audio/mpeg" />
                  Your browser does not support the audio element
                </audio>
               
              </div>
            </Card.Body>
          </Card>


        </Col>


      </Row>

      <Row>
        <Link to={`/socialpoems`}>

          <Button className="go-back-button" style={{ fontSize: "18px", marginLeft: "12px", marginTop: "16px", marginBottom: "16px" }} variant="outline"><span className='go-back-span'>Go Back</span></Button>
        </Link>
      </Row>
    </>
  );
}

