import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import { Link } from 'react-router-dom';
import $ from 'jquery';
import aboutCSS from './css/about.css';
import aboutIMG from './img/image-about.png'

import { useTranslation } from 'react-i18next';

export const AboutView = () => {
  

  
  const { t } = useTranslation();
  return (

    <Container fluid className="aboutAnimation wrapper close-notification aboutContainer pt-5">
      <Row className="row-about">

        <Col style={{ textAlign: "right" }} className="h-100 row-about-info" xs={12} md={8}>
        <div className="about-view-quote-container">
        <span className="about-view-quote">"If you cannot be a poet, </span><span className="about-view-quote-2">be the poem."</span><span><em className="about-view-quote-author">David Carradine</em></span>

        </div>
          

          
          
        </Col>
        <Col className="row-about-image" xs={12} md={3}>
          <Image alt={t("profileImageAlt")}  className="img-responsive profile-picture pt-3 pb-3" fluid src={aboutIMG} />
        </Col>
      </Row>


















    </Container>

  );

};