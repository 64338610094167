import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import hands from './img/hands.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/should.mp3'
import video from './audio/should.mp4'



export const ShouldHaveBeenSo = () =>{
  return (
    <>
<Row className='m-auto'>
    <Col  className='m-auto col-single-poem'>
    <Card className="card m-auto"
    // style={{ width: '28rem' }}
    >
       <video width="750" height="500" controls autoplay loop muted>
              <source src={video} type="video/mp4" />
            </video>
      {/* <Card.Img className="w-75 m-auto" variant="top" src={hands} /> */}
      <Card.Body className="">
        <h4 className="text-center single-poem-heading">Значить це мало так бути</h4>
        <Card.Text className="text-center single-poem-text">
          <p>
          Я кажу: «Ти мене будиш<br/>
Від цього світу як смути».<br/>
Шепотом слух мій голубиш:<br/>
«Значить це мало так бути».<br/>
<br/>
Я кажу: «Ти ж бо не судиш,<br/>
Навіть як повен отрути».<br/>
Шепотом слух мій голубиш:<br/>
«Значить це мало так бути».<br/>
<br/>
Я кажу: «Осад забудеш?<br/>
Ти мене можеш відчути?»<br/>
Шепотом слух мій «голубиш»:<br/>
«Значить це мало так бути».<br/>
<br/>
Я сказав «Так? Вже не любиш.<br/>
Образ твій бачу усюди».<br/>
Шепотом слух мій «голубиш»:<br/>
«Значить це мало так бути».<br/>

          </p>
        </Card.Text>
        <div className='text-center'>
                <audio className='mt-3 mb-3' controls>
                  <source src={audio} type="audio/mpeg" />
                  Your browser does not support the audio element
                </audio>
              </div>
      </Card.Body>
    </Card>

    
    </Col>
    
    
    </Row>

<Row>
<Link to={`/lovepoems`}>

        <Button className="go-back-button" style={{fontSize:"18px", marginLeft:"12px", marginTop:"16px", marginBottom:"16px"}} variant="outline"><span className='go-back-span'>Go Back</span></Button>
        </Link>
</Row>
</>
  );
}

