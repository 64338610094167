import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import matrix from './img/matrix.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/matrix.mp3'
import video from './audio/matrix.mp4'



export const Matrix = () =>{
  return (
    <>
<Row className='m-auto'>
    <Col  className='m-auto col-single-poem'>
    <Card className="card m-auto"
    // style={{ width: '28rem' }}
    >
      <video width="750" height="500" controls autoplay loop muted>
          <source src={video} type="video/mp4" />
        </video>
      {/* <Card.Img className="w-75 m-auto" variant="top" src={matrix} /> */}
      <Card.Body className="">
        <h4 className="text-center single-poem-heading">Red pill, blue pill</h4>
        <Card.Text className="text-center single-poem-text">
          <p>
          Я знаю почему сидишь за монитором<br/>
И ночи напролет не покидаешь сеть.<br/>
Я знаю почему стал иллюзорен город,<br/>
И тщетны все попытки бред с памяти стереть.<br/>
<br/>
Ты падаешь безвольно, как юная Алиса,<br/>
Все дальше погружаясь в мир цифр и программ.<br/>
Загадкам нет ответов, и нет в загадках смысла,<br/>
Как не смотри под утро в мерцающий экран.<br/>
<br/>
И просыпаясь рано, в захламленной квартире<br/>
И помогая мусор консьержке выносить,<br/>
Ты думаешь о чем-то, впадая в ностальгию,<br/>
Вопросы ускользают, и рвется мыслей нить.<br/>
<br/>
«В одной руке – забвение, продление алгоритма,<br/>
Тебе все это снится, и вечна полутьма.<br/>
В другой – твое спасение, услышит Бог молитвы,<br/>
Я покажу как может быть глубока нора».<br/>



          </p>
        </Card.Text>
        <div className='text-center'>
        <audio className='mt-3 mb-3' controls>
<source  src={audio} type="audio/mpeg" />
Your browser does not support the audio element
</audio>
</div>
      </Card.Body>
    </Card>

    
    </Col>
    
    
    </Row>
    <Row>
    <Link to={`/philosophypoems`}>
    
            <Button className="go-back-button" style={{fontSize:"18px", marginLeft:"12px", marginTop:"16px", marginBottom:"16px"}} variant="outline"><span className='go-back-span'>Go Back</span></Button>
            </Link>
    </Row>
        
        </>
  );
}

