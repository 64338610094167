import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import words from './img/words.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/loneliness.mp3'
import video from './audio/loneliness.mp4'



export const Loneliness = () => {
  return (
    <>
      <Row className='m-auto'>
        <Col className='m-auto col-single-poem'>
          <Card className="card m-auto"
          // style={{ width: '28rem' }}
          >
            {/* <Card.Img className="w-75 m-auto" variant="top" src={words} /> */}
            <video width="750" height="500" controls autoplay loop muted>
              <source src={video} type="video/mp4" />
            </video>
            <Card.Body className="">
              <h4 className="text-center single-poem-heading">Одиночество</h4>
              <Card.Text className="text-center single-poem-text">
                <p>
                  Я  надеваю  на сумрачный  лик,  будто бы мим я невыспанный<br />
                  Маску  веселья,  к  которой  привык,  грустный свой грим, но изысканный.<br />
                  Ночью  на  улицах  люди  в  пальто,  призрачным  счастьем  наполнены<br />
                  Крика  сердец  не  услышит  никто,  если  молчать  уготовано.<br />
                  <br />
                  Но  по  утрам,  среди  жаркого  дня,  ум  мой  сражен  отвращением<br />
                  Запах  цветов  так  и  душит  меня,  тьма  для  меня  –  облегчение.<br />
                  Птицы  с  своим  песнопением  вдруг  жалким  становятся  зрелищем<br />
                  Мучают  звуками  нежный  мой  слух, мир  мой  -  театр,  посмешище.<br />
                  <br />
                  В  стенах  пропитанных  крепким  спиртным,  кофе  и  вечным  молчанием<br />
                  Я  осознал,  что  давно  стал  больным,  и  подчинился  страданиям.<br />
                  Все  мы  болеем  заразой  одной,  что  никогда  не  закончиться<br />
                  Эту  болезнь  я  назвал  бы  чумой,  эта  болезнь  –  одиночество.<br />


                </p>
              </Card.Text>
              <div className='text-center'>
                <audio className='mt-3 mb-3' controls>
                  <source src={audio} type="audio/mpeg" />
                  Your browser does not support the audio element
                </audio>
              </div>
            </Card.Body>
          </Card>


        </Col>


      </Row>

      <Row>
        <Link to={`/socialpoems`}>

          <Button className="go-back-button" style={{ fontSize: "18px", marginLeft: "12px", marginTop: "16px", marginBottom: "16px" }} variant="outline"><span className='go-back-span'>Go Back</span></Button>
        </Link>
      </Row>
    </>
  );
}

