import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import letters from './img/letters.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/friendship.mp3'
import video from './audio/letters.mp4'



export const Letters = () => {
  return (
    <>
      <Row className='m-auto'>
        <Col className='m-auto col-single-poem'>
          <Card className="card m-auto"
          // style={{ width: '28rem' }}
          ><video width="750" height="500" controls autoplay loop muted>
          <source src={video} type="video/mp4" />
        </video>
            {/* <Card.Img className="w-75 m-auto" variant="top" src={letters} /> */}
            <Card.Body className="">
              <h4 className="text-center single-poem-heading">Листи</h4>
              <Card.Text className="text-center single-poem-text">
                <p>
                Час більше не ходить, біжить безупинно.<br/>
І мій час спливає, не хоче іти.<br/>
Яка ж насолода, в самотні хвилини<br/>
Від друзів читати пожовклі листи.<br/>
<br/>
Чому так нечасто всміхаються люди?<br/>
Чому не радіють дрібницям простим?<br/>
Усе те прекрасне, що ми звемо чудом<br/>
В собі зберігають звичайні листи.<br/>
<br/>
Якщо ваша скринька порожня й самотня,<br/>
І швидко ваш час за вікном пролетів,<br/>
У теплих будинках, у вечір суботній<br/>
Можливо від вас ще чекають листів.<br/>


                </p>
              </Card.Text>
              <div className='text-center'>
                <audio className='mt-3 mb-3' controls>
                  <source src={audio} type="audio/mpeg" />
                  Your browser does not support the audio element
                </audio>
              </div>
            </Card.Body>
          </Card>


        </Col>


      </Row>

      <Row>
        <Link to={`/socialpoems`}>

          <Button className="go-back-button" style={{ fontSize: "18px", marginLeft: "12px", marginTop: "16px", marginBottom: "16px" }} variant="outline"><span className='go-back-span'>Go Back</span></Button>
        </Link>
      </Row>
    </>
  );
}

