import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import bethere from './img/bethere.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/bethere.mp3'
import video from './audio/dawn.mp4'



export const BeThere = () =>{
  return (
    <>
<Row className='m-auto'>
    <Col  className='m-auto col-single-poem'>
    <Card className="card m-auto"
    // style={{ width: '28rem' }}
    >
      {/* <Card.Img className="w-75 m-auto" variant="top" src={bethere} /> */}
      <video width="750" height="500" controls autoplay loop muted>
              <source src={video} type="video/mp4" />
            </video>
      <Card.Body className="">
        <h4 className="text-center single-poem-heading">Be there, dark at dawn</h4>
        <Card.Text className="text-center single-poem-text">
          <p>
          Be there, dark at dawn,<br/>
When I run out of prayers.<br/>
Words sound like they’re betrayers.<br/>
Now all of them are gone.<br/>
<br/>
Be darkness in the day.<br/>
The one who’s always fair.<br/>
So that I won’t beware<br/>
In case the world’s insane.<br/>
<br/>
Be darkness on the eve,<br/>
And I won’t be in sorrow.<br/>
I know you’ll come tomorrow<br/>
And never, never leave.<br/>
<br/>
Be there, dark at night.<br/>
Because smiles do not matter.<br/>
If wounds make people better,<br/>
Then darkness is the light.<br/>

 


          </p>
        </Card.Text>

        <div className='text-center'>
                <audio className='mt-3 mb-3' controls>
                  <source src={audio} type="audio/mpeg" />
                  Your browser does not support the audio element
                </audio>
              </div>
        </Card.Body>
    </Card>

    
    </Col>
    
    
    </Row>

<Row>
<Link to={`/lovepoems`}>

        <Button className="go-back-button" style={{fontSize:"18px", marginLeft:"12px", marginTop:"16px", marginBottom:"16px"}} variant="outline"><span className='go-back-span'>Go Back</span></Button>
        </Link>
</Row>
</>
  );
}

