import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import halfway from './img/halfway.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/halfway.mp3'
import video from './audio/halfway.mp4'



export const Halfway = () => {
  return (
    <>
      <Row className='m-auto'>
        <Col className='m-auto col-single-poem'>
          <Card className="card m-auto"
          // style={{ width: '28rem' }}
          >
            {/* <Card.Img className="w-75 m-auto" variant="top" src={halfway} /> */}
            <video width="750" height="500" controls autoplay loop muted>
              <source src={video} type="video/mp4" />
            </video>
            <Card.Body className="">
              <h4 className="text-center single-poem-heading">на полпути</h4>
              <Card.Text className="text-center single-poem-text">
                <p>
                  на полпути сомкнулся круг.<br />
                  куда теперь, мой верный друг?<br />
                  дождливый день, угрюма ночь<br />
                  ничем не смогут мне помочь.<br />
                  <br />
                  я оторву свой взгляд от тьмы<br />
                  и в пропасть прыгну до весны.<br />
                  когда зажжется в сердце свет -<br />
                  сойдется путь моих планет.<br />
                  <br />
                  закрыть глаза на грусть и ложь - <br />
                  охватит душу ливень-дрожь.<br />
                  на полпути дождливых дум<br />
                  остановите, я сойду.<br />

                </p>
              </Card.Text>
              <div className='text-center'>
                <audio className='mt-3 mb-3' controls>
                  <source src={audio} type="audio/mpeg" />
                  Your browser does not support the audio element
                </audio>
              </div>
            </Card.Body>
          </Card>


        </Col>


      </Row>
      <Row>
        <Link to={`/philosophypoems`}>

          <Button className="go-back-button" style={{ fontSize: "18px", marginLeft: "12px", marginTop: "16px", marginBottom: "16px" }} variant="outline"><span className='go-back-span'>Go Back</span></Button>
        </Link>
      </Row>

    </>
  );
}

