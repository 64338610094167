import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import bolero from './img/bolero.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/bolero.mp3'
import video from './audio/bolero.mp4'



export const Bolero = () => {
  return (
    <>
      <Row className='m-auto'>
        <Col className='m-auto col-single-poem'>
          <Card className="card m-auto"
          // style={{ width: '28rem' }}
          >
            <video width="750" height="500" controls autoplay loop muted>
              <source src={video} type="video/mp4" />
            </video>
            {/* <Card.Img className="w-75 m-auto" variant="top" src={bolero} /> */}
            <Card.Body className="">
              <h4 className="text-center single-poem-heading">Болеро</h4>
              <Card.Text className="text-center single-poem-text">
                <p>
                  Весь город спит, как будто вечно, и за окном нет ни души,<br />
                  И на стенах животрепещат вновь предзакатные лучи.<br />
                  Нет шума суетливых улиц, не слышно громоздких машин,<br />
                  И все как будто окунулось, в объятия вечной тишины.<br />
                  <br />
                  Не слышно как скрипят качели, ни слова на сухих губах,<br />
                  И город, будто из пастели, молчанием августа пропах.<br />
                  Плывут седые каравеллы, их белоснежны паруса,<br />
                  А ветер держит курс на север, куда-то дальше, в небеса. <br />
                  <br />
                  Но вдруг я слышу, словно эхо, играет что-то не спеша,<br />
                  Сперва, неслышно, незаметно, как будто листьями шурша.<br />
                  И в свете ярком, предзакатном , звучит знакомый мне мотив,<br />
                  Там медленно играют такты, и этот гимн неповторим.<br />
                  <br />
                  Быть может это звук  тромбонов, играют скрипки и труба?<br />
                  Их голоса неугомонно роняют теплые уста?<br />
                  Но чем длинней симфоний шепот, тем кажется все больше мне,<br />
                  Что музыку рождает город, он утопает в полутьме.<br />
                  <br />
                  Он соткан шепотом деревьев, из песен птиц, цикад и рек,<br />
                  И ветра легким дуновением, в нем громкий плач и звонкий смех.<br />
                  Там, за окном, проснулся город, искриться и играет жизнь,<br />
                  И каждый звук, и каждый шорох – струиться из его души.<br />


                </p>
              </Card.Text>
              <div className='text-center'>
                <audio className='mt-3 mb-3' controls>
                  <source src={audio} type="audio/mpeg" />
                  Your browser does not support the audio element
                </audio>
              </div>
            </Card.Body>
          </Card>


        </Col>


      </Row>
      <Row>
        <Link to={`/philosophypoems`}>

          <Button className="go-back-button" style={{ fontSize: "18px", marginLeft: "12px", marginTop: "16px", marginBottom: "16px" }} variant="outline"><span className='go-back-span'>Go Back</span></Button>
        </Link>
      </Row>

    </>
  );
}

