import Card from 'react-bootstrap/Card';
import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import css from './css/about.css';
import words2 from './img/words2.jpg';
import { Link } from 'react-router-dom';
import audio from './audio/indifference.mp3'
import video from './audio/words.mp4'


export const Words = () => {

  return (
    <>
      <Row className='m-auto'>
        <Col className='m-auto col-single-poem'>
          <Card className="card m-auto"
          // style={{ width: '28rem' }}
          ><video width="750" height="500" controls autoplay loop muted>
              <source src={video} type="video/mp4" />
            </video>
            {/* <Card.Img className="w-75 m-auto" variant="top" src={words2} /> */}
            <Card.Body className="">
              <h4 className="text-center single-poem-heading">Слова</h4>
              <Card.Text className="text-center single-poem-text">
                <p>
                  Слова.. отрута в них і світло,<br />
                  Людська надія і жага.<br />
                  Їх складність форм і простота,<br />
                  Життям від істини налита,<br />
                  В пустелях душ зростає цвітом.<br />
                  <br />
                  В словах ключі до всіх замків;<br />
                  В словах ненависть і кохання.<br />
                  Коли би кожен зрозумів,<br />
                  Усю величність й силу слів,<br />
                  Тоді б закінчились страждання.<br />

                </p>
                {/* <audio src={audio} controlls ></audio> */}

              </Card.Text>
              <div className='text-center'>
                <audio className='mt-3 mb-3' controls>
                  <source src={audio} type="audio/mpeg" />
                  Your browser does not support the audio element
                </audio>
              </div>
              {/* <audio  type="audio/mp3" ></audio> */}

            </Card.Body>
          </Card>


        </Col>


      </Row>

      <Row>
        <Link to={`/socialpoems`}>

          <Button className="go-back-button" style={{ fontSize: "18px", marginLeft: "12px", marginTop: "16px", marginBottom: "16px" }} variant="outline"><span className='go-back-span'>Go Back</span></Button>
        </Link>
      </Row>
    </>
  );
}

